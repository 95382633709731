<template>
	<div class="row m-0">
      <div class="col-12 p-0">
         <div class="card mb-2">
            <div class="card-body pb-2">
               <div class="row m-0">
                  <div class="col-6 col-md-4 col-xl-5 mb-2 px-1">
                     <label><i class="far fa-stream color-theme font-12 me-1"></i> Tipo de pesquisa</label>
                     <v-select :options="['Identidade', 'Steam', 'Permissão', 'Whitelist', 'Código de aprovação']" v-model="pesquisa.tipo" placeholder="Selecione um tipo" />
                  </div>
                  <div class="col-6 col-md-4 col-xl-5 mb-2 px-1" v-if="pesquisa.tipo == 'Whitelist'">
                     <label><i class="far fa-scroll color-theme font-12 me-1"></i> Whitelist</label>
                     <v-select :options="['Não aprovado', 'Aprovado']" v-model="pesquisa.whitelist" placeholder="Selecione uma opção" />
                  </div>
                  <div class="col-6 col-md-4 col-xl-5 mb-2 px-1" v-else-if="pesquisa.tipo == 'Código de aprovação'">
                     <label><i class="far fa-hashtag color-theme font-12 me-1"></i> Código de aprovação</label>
                     <input type="text" class="form-control" v-model="pesquisa.codAprovacao" maxlength="100" v-focus placeholder="Código de aprovação" @keyup.enter="searchUsers" />
                  </div>
                  <div class="col-6 col-md-4 col-xl-5 mb-2 px-1" v-else-if="pesquisa.tipo == 'Identidade'">
                     <label><i class="far fa-hashtag color-theme font-12 me-1"></i> Identidade</label>
                     <input type="text" class="form-control" v-model="pesquisa.id" maxlength="100" v-focus placeholder="Identidade" @keyup.enter="searchUsers" />
                  </div>
                  <div class="col-6 col-md-4 col-xl-5 mb-2 px-1" v-else-if="pesquisa.tipo == 'Steam'">
                     <label><i class="fab fa-steam color-theme font-12 me-1"></i> Steam</label>
                     <input type="text" class="form-control" v-model="pesquisa.steam" maxlength="100" v-focus placeholder="Steam" @keyup.enter="searchUsers" />
                  </div>
                  <div class="col-6 col-md-4 col-xl-5 mb-2 px-1" v-else>
                     <label><i class="far fa-lock color-theme font-12 me-1"></i> Permissão</label>
                     <v-select :options="listaPermissoes" v-model="pesquisa.permissao" placeholder="Selecione uma opção" />
                  </div>
                  <div class="col-md-4 col-xl-2 mb-2 px-1 align-self-end">
                     <button type="button" class="btn btn-primary w-100" @click="searchUsers">
                        <i class="fas fa-search"></i>
                     </button>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <div class="col-12 py-5 text-center" v-if="resultado.length == 0">Nenhum jogador encontrado</div>

      <div class="col-12 p-0" v-else>
         <div class="row m-0">
            <jogador v-for="(jogador, index) in resultado" :key="index" :jogador="jogador" :index="index" @getInfoUser="getInfoUser($event)" />
         </div>
      </div>

      <!-- modalUsuario -->
      <div class="modal fade" id="modalUsuario">
         <div class="modal-dialog modal-xxl h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalUsuarioLabel">Detalhes do jogador</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body py-3">
                  <div class="card mx-1 mb-2" v-if="!add.veiculo && !add.permissao">
                     <div class="card-body p-3"> 
                        <ul class="nav nav-tabs p-0 border-0" role="tablist">
                           <li class="nav-item me-2">
                              <a class="nav-link rounded active" data-bs-toggle="tab" href="#tabOne">
                                 <i class="fal fa-backpack normal me-1"></i> Itens
                              </a>
                           </li>
                           <li class="nav-item me-2">
                              <a class="nav-link rounded" data-bs-toggle="tab" href="#tabTwo">
                                 <i class="fal fa-car-side normal me-1"></i> Veículos
                              </a>
                           </li>
                           <li class="nav-item">
                              <a class="nav-link rounded" data-bs-toggle="tab" href="#tabThree">
                                 <i class="fal fa-lock normal me-1"></i> Permissões
                              </a>
                           </li>
                        </ul>
                     </div>
                  </div>
                        
                  <div class="card mx-1" v-if="!add.veiculo && !add.permissao">
                     <div class="card-body p-2">
                        <div class="tab-content">
                           <div role="tabpanel" class="tab-pane in active" id="tabOne">
                              <div class="cards-0">
                                 <div class="card mb-0 position-relative invert" v-for="(i, index) in infoUser.itens" :key="index">
                                    <div class="card-body text-center p-2">
                                       <img class="mb-2 rounded" :src="urlFotoItens + i.nome +'.png'" @error="imageError" /><br>
                                       <h6 class="m-0 limitador">{{ i.nome }}</h6>
                                       <small>{{ i.qtd }} <small>UN</small></small>
                                    </div>
                                 </div>
                              </div>

                              <div class="mb-2 text-center" v-if="infoUser.itens == null || infoUser.itens.length == 0">Nenhum item encontrado.</div>
                           </div>
                           <div role="tabpanel" class="tab-pane" id="tabTwo">
                              <div class="cards-0">
                                 <div class="card mb-0 position-relative invert" v-for="(v, index) in infoUser.veiculos" :key="index">
                                    <div class="card-body text-center p-2">
                                       <i class="far fa-trash position-absolute top-0 right-0 cursor-pointer m-2 normal font-15 text-danger" @click="removeVehicleUser(v.nome, index)"></i>
                                       <img class="mb-2 rounded" :src="urlFotoVeiculos + v.nome +'.png'" @error="imageError" /><br>
                                       <h6 class="m-0 limitador">{{ v.nome }}</h6>
                                       <small><i class="far fa-car-side color-theme font-10 me-1"></i>{{ parseInt(v.body) / 10 }}<small>%</small></small>
                                       <small class="ms-2"><i class="far fa-gas-pump color-theme font-10 me-1"></i>{{ v.fuel }}<small>%</small></small><br>
                                       <small><i class="far fa-car-battery color-theme font-10 me-1"></i>{{ parseInt(v.engine) / 10 }}<small>%</small></small>
                                    </div>
                                 </div>
                              </div>

                              <div class="mb-2 text-center" v-if="infoUser.veiculos == null || infoUser.veiculos.length == 0">Nenhum veículo encontrado.</div>
                           </div>
                           <div role="tabpanel" class="tab-pane" id="tabThree">
                              <div class="cards-2">
                                 <div class="card mb-0 invert" v-for="(perm, index) in infoUser.permissoes" :key="index">
                                    <div class="card-body text-center">
                                       <i class="far fa-trash float-end cursor-pointer normal font-15 text-danger ms-2" @click="removePermissionUser(perm, index)"></i>
                                       <h6 class="m-0 limitador">{{ perm }}</h6>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  
                  <div class="col-12 p-0" v-if="add.veiculo">
                     <div class="row m-0">
                        <div class="col-12 p-0">
                           <div class="card mb-2">
                              <div class="card-body p-3">
                                 <label class="form-label w-100">
                                    <i class="far fa-car-side font-12 color-theme me-1"></i> Veículo <span class="color-theme float-end">Pressione 'enter' para adicionar</span>
                                 </label>
                                 <input type="text" class="form-control searchInput" v-model="add.searchVehicle" placeholder="Pesquisar ou enter para adicionar..." @keyup.enter="setVehicleUser(add.searchVehicle)" />
                              </div>
                           </div>
                        </div>

                        <div class="col-12 p-0">
                           <div class="card mb-2">
                              <div class="card-body p-3">
                                 <h6 class="font-16 mx-2 mt-1"><i class="fas fa-angle-right font-15 color-theme me-1"></i> Selecione um veículo</h6>

                                 <div id="searchVehicle" class="cards-0">
                                    <div class="card mb-0 invert hover" v-for="(veiculo, index) in listaVeiculos" :key="index" @click="setVehicleUser(veiculo)">
                                       <div class="card-body text-center px-2 py-3 cursor-pointer">
                                          <img class="mb-2 rounded" :src="urlFotoVeiculos + veiculo +'.png'" @error="imageError" />
                                          <h6 class="mb-0 mt-1 limitador weight-700 font-14">{{ veiculo }}</h6>
                                       </div>
                                    </div>
                                 </div>

                                 <p class="font-16 mx-2 text-center" v-if="listaVeiculos == null || listaVeiculos.length == 0">Nenhum veiculo encontrado.</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  
                  <div class="col-12 p-0" v-if="add.permissao">
                     <div class="row m-0">
                        <div class="col-12 p-0">
                           <div class="card mb-2">
                              <div class="card-body p-3">
                                 <label class="form-label w-100">
                                    <i class="far fa-lock font-12 color-theme me-1"></i> Permissão <span class="color-theme float-end">Pressione 'enter' para adicionar</span>
                                 </label>
                                 <input type="text" class="form-control searchInput" v-model="add.searchPerm" placeholder="Pesquisar ou enter para adicionar..." @keyup.enter="setPermissionUser(add.searchPerm)" />
                              </div>
                           </div>
                        </div>

                        <div class="col-12 p-0">
                           <div class="card mb-2">
                              <div class="card-body p-3">
                                 <h6 class="font-16 mx-2 mt-1"><i class="fas fa-angle-right font-15 color-theme me-1"></i> Selecione uma permissão</h6>

                                 <div id="searchPerm" class="cards-0">
                                    <div class="card mb-0 invert hover" v-for="(permissao, index) in listaPermissoes" :key="index" @click="setPermissionUser(permissao)">
                                       <div class="card-body text-center p-12 cursor-pointer">
                                          <h6 class="m-0 limitador weight-700 font-14">{{ permissao }}</h6>
                                       </div>
                                    </div>
                                 </div>

                                 <p class="font-16 mx-2 text-center" v-if="listaPermissoes == null || listaPermissoes.length == 0">Nenhuma permissão encontrada.</p>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-secondary me-2" @click="toggleAdd('VEICULO')" v-if="!add.veiculo && !add.permissao">
                     <i class="far fa-plus font-12 me-2"></i>Veículo
                  </button>
                  <button type="button" class="btn btn-secondary me-2" @click="toggleAdd('PERMISSAO')" v-if="!add.veiculo && !add.permissao">
                     <i class="far fa-plus font-12 me-2"></i>Permissão
                  </button>
                  <button type="button" class="btn btn-secondary me-2" @click="toggleAdd('RESET')" v-if="add.veiculo || add.permissao">
                     <i class="far fa-undo font-12 me-2"></i>Voltar
                  </button>
                  <button type="button" class="btn btn-danger btn-simple waves-effect" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import jogador from '@/components/admin/jogadores/Jogador.vue'

export default {
	name: 'AdmJogadores_pesquisa',
   data: function () {
      return {
         pesquisa: {'tipo': 'Identidade', 'permissao': null, 'whitelist': null, 'id': '', 'steam': '', 'codAprovacao': ''},
         resultado: [],
         add: {'veiculo': false, 'permissao': false, 'searchVehicle': '', 'searchPerm': ''},
         infoUser: {"id": 0, "itens": [], "permissoes": [], "veiculos": []},
      }
   },
   watch: {
      'add.searchVehicle' : function (val) {
         var value = val.toLowerCase()

         $("#searchVehicle h6").filter(function () {
            $(this.parentElement.parentElement).toggle($(this).text().toLowerCase().indexOf(value) > -1)
         })
      },
      'add.searchPerm' : function (val) {
         var value = val.toLowerCase()

         $("#searchPerm h6").filter(function () {
            $(this.parentElement.parentElement).toggle($(this).text().toLowerCase().indexOf(value) > -1)
         })
      }
   },
   computed: {
      ... mapState({
         listaPermissoes: state => state.listaPermissoes,
         listaVeiculos: state => state.listaVeiculos,
         urlFotoVeiculos: state => state.urlFotoVeiculos,
         urlFotoItens: state => state.urlFotoItens,
         dadosUsuario: state => state.dadosUsuario,
         logado: state => state.logado,
         urlRest: state => state.urlRest
      })
   },
   components: {
      jogador
   },
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      searchUsers : function () {
         if (this.pesquisa.tipo == null) {
            this.$toast.fire({
               icon: 'error',
               title: 'Selecione uma tipo de pesquisa!'
            });

            return
         } else if (this.pesquisa.tipo == 'Permissão' && this.pesquisa.permissao == null) {
            this.$toast.fire({
               icon: 'error',
               title: 'Selecione uma opção!'
            });

            return
         } else if (this.pesquisa.tipo == 'Whitelist' && this.pesquisa.whitelist == null) {
            this.$toast.fire({
               icon: 'error',
               title: 'Selecione uma opção!'
            });

            return
			} else if (this.pesquisa.tipo == 'Identidade' && (this.pesquisa.id == null || String(this.pesquisa.id).trim().length == 0)) {
            this.$toast.fire({
               icon: 'error',
               title: 'Informe um valor!'
            });

            return
			} else if (this.pesquisa.tipo == 'Steam' && (this.pesquisa.steam == null || String(this.pesquisa.steam).trim().length == 0)) {
            this.$toast.fire({
               icon: 'error',
               title: 'Informe um valor!'
            });

            return
         } else if (this.pesquisa.tipo == 'Código de aprovação' && (this.pesquisa.codAprovacao == null || String(this.pesquisa.codAprovacao).trim().length == 0)) {
            this.$toast.fire({
               icon: 'error',
               title: 'Informe uma valor!'
            });

            return
         }

         let valor = '';

			switch (this.pesquisa.tipo) {
				case 'Identidade':
					valor = this.pesquisa.id
				break;
				case 'Steam':
					valor = this.pesquisa.steam
				break;
				case 'Permissão':
					valor = this.pesquisa.permissao
				break;
				case 'Whitelist':
					valor = this.pesquisa.whitelist
				break;
				case 'Código de aprovação':
					valor = this.pesquisa.codAprovacao
				break;
			}

         let ref = this;
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.resultado = []

         this.$axios({
            method: 'get',
            url: this.urlRest +'game/searchUsers',
            headers: {
               'Authorization': 'Bearer ' + this.logado.token +'@@@'+ this.dadosUsuario.admin.usuarioUsername 
            },
            params: {
               tipo: this.pesquisa.tipo,
               valor: valor
            }
         }).then(response => {
				this.resultado = response.data

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogarAdmin')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      getInfoUser : function (id) {
         let ref = this;
			this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'get',
            url: this.urlRest +'game/getInfoUser',
            headers: {
               'Authorization': 'Bearer ' + this.logado.token +'@@@'+ this.dadosUsuario.admin.usuarioUsername 
            },
            params: {
               id: id
            }
         }).then(function (response) {
            ref.infoUser = response.data
            ref.infoUser.id = id

				$('#modalUsuario').modal('show')

         }).catch(function (error) {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  ref.$store.dispatch('deslogarAdmin')

                  ref.$toast.fire({
                     icon: 'warning',
                     title: 'Sessão expirada!'
                  });
               } else {
                  ref.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  })
               }
            } else {
               ref.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      },
      toggleAdd : function(tipo) {
         this.$store.dispatch('alternarTelaCarregamento', true)

         setTimeout(() => {
            if (tipo == 'VEICULO') {
               this.add.veiculo = true;

            } else if (tipo == 'PERMISSAO') {
               this.add.permissao = true;

            } else {
               this.add = {'veiculo': false, 'permissao': false, 'searchVehicle': '', 'searchPerm': ''}
            }
         }, 250);
         
         setTimeout(() => {
            $(".searchInput").focus()
            this.$store.dispatch('alternarTelaCarregamento', false)
         }, 500);
      },
      setPermissionUser : function(permissao) {
         if (permissao == null || String(permissao).trim().length == 0) {
            this.$toast.fire({
               title: 'Permissão inválida!',
               icon: 'error'
            })

            return
         }

         let ref = this;

         Swal.fire({
            icon: 'warning',
            title: 'Adicionar permissão',
            text: 'Informe o tempo (Dias)',
            input: 'text',
            inputPlaceholder: 'Dias',
            customClass: {
               input: 'shadow-none'
            },
            confirmButtonText: 'Aplicar',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            showDenyButton: true,
            denyButtonText: 'Permanente',
            inputValidator: (value) => {
               if (!$.isNumeric(value)) {
                  return 'Tempo inválido!'
               }
            }
         }).then((result) => {
            if (!result.isDismissed) {
               ref.$store.dispatch('alternarTelaCarregamento', true)
               
               ref.$axios({
                  method: 'post',
                  url: ref.urlRest +'game/setPermissionUser',
                  headers: {
                     'Authorization': 'Bearer ' + ref.logado.token +'@@@'+ ref.dadosUsuario.admin.usuarioUsername
                  },
                  params:{
                     id: ref.infoUser.id,
                     permission: permissao,
                     time: (result.isConfirmed ? result.value : 0)
                  }
               }).then(function () {
                  ref.infoUser.permissoes.push(permissao)

                  ref.$toast.fire({
                     icon: 'success',
                     title: 'Permissão adicionada!'
                  })
               }).catch(function (error) {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        ref.$store.dispatch('deslogarAdmin')

                        ref.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        ref.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     ref.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  ref.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         })
      },
      removePermissionUser : function(permissao, index) {
         let ref = this;

         Swal.fire({
            icon: 'warning',
            title: 'Remover permissão',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar'

         }).then((result) => {
            if (result.isConfirmed) {
               ref.$store.dispatch('alternarTelaCarregamento', true)

               ref.$axios({
                  method: 'post',
                  url: ref.urlRest +'game/removePermissionUser',
                  headers: {
                     'Authorization': 'Bearer ' + ref.logado.token +'@@@'+ ref.dadosUsuario.admin.usuarioUsername
                  },
                  params:{
                     id: ref.infoUser.id,
                     permission: permissao
                  }
               }).then(function () {
                  ref.infoUser.permissoes.splice(index, 1)

                  ref.$toast.fire({
                     icon: 'success',
                     title: 'Permissão removida!'
                  })
               }).catch(function (error) {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        ref.$store.dispatch('deslogarAdmin')

                        ref.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        ref.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     ref.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  ref.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         })
      },
      setVehicleUser : function(veiculo) {
         let ref = this;

         Swal.fire({
            icon: 'warning',
            title: 'Adicionar veículo',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar'

         }).then((result) => {
            if (result.isConfirmed) {
               ref.$store.dispatch('alternarTelaCarregamento', true)

               ref.$axios({
                  method: 'post',
                  url: ref.urlRest +'game/setVehicleUser',
                  headers: {
                     'Authorization': 'Bearer ' + ref.logado.token +'@@@'+ ref.dadosUsuario.admin.usuarioUsername
                  },
                  params:{
                     id: ref.infoUser.id,
                     vehicle: veiculo
                  }
               }).then(function () {
                  ref.infoUser.veiculos.push({
                     "body": 1000,
                     "engine": 1000,
                     "fuel": 100,
                     "nome": veiculo
                  })

                  ref.$toast.fire({
                     icon: 'success',
                     title: 'Veículo adicionado!'
                  })
               }).catch(function (error) {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        ref.$store.dispatch('deslogarAdmin')

                        ref.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        ref.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     ref.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  ref.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         })
      },
      removeVehicleUser : function(veiculo, index) {
         let ref = this;

         Swal.fire({
            icon: 'warning',
            title: 'Remover veículo',
            showCancelButton: true,
            cancelButtonText: 'Cancelar',
            confirmButtonText: 'Confirmar'

         }).then((result) => {
            if (result.isConfirmed) {
               ref.$store.dispatch('alternarTelaCarregamento', true)

               ref.$axios({
                  method: 'post',
                  url: ref.urlRest +'game/removeVehicleUser',
                  headers: {
                     'Authorization': 'Bearer ' + ref.logado.token +'@@@'+ ref.dadosUsuario.admin.usuarioUsername
                  },
                  params:{
                     id: ref.infoUser.id,
                     vehicle: veiculo
                  }
               }).then(function () {
                  ref.infoUser.veiculos.splice(index, 1)

                  ref.$toast.fire({
                     icon: 'success',
                     title: 'Veículo removido!'
                  })
               }).catch(function (error) {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        ref.$store.dispatch('deslogarAdmin')

                        ref.$toast.fire({
                           icon: 'warning',
                           title: 'Sessão expirada!'
                        });
                     } else {
                        ref.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     ref.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  ref.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         })
      }
   },
   mounted() {
      this.$store.dispatch('buscarPermissoes')
      this.$store.dispatch('buscarVeiculos')
   }
}

</script>